.spinner {
  position: relative;
  width: 30px;
  height: 30px;
}

.spinner div {
  width: 100%;
  height: 100%;
  background-color: #822259;
  border-radius: 50%;
  animation: spinner-4t3wzl 1.25s infinite backwards;
}

.spinner div:nth-child(1) {
  animation-delay: 0.15s;
  background-color: rgb(128, 31, 86);
}

.spinner div:nth-child(2) {
  animation-delay: 0.3s;
  background-color: rgb(237, 11, 104);
}

.spinner div:nth-child(3) {
  animation-delay: 0.45s;
  background-color: rgb(207, 146, 161);
}

.spinner div:nth-child(4) {
  animation-delay: 0.6s;
  background-color: rgb(215, 94, 135);
}

.spinner div:nth-child(5) {
  animation-delay: 0.75s;
  background-color: rgb(124, 36, 86);
}

@keyframes spinner-4t3wzl {
  0% {
    transform: rotate(0deg) translateY(-200%);
  }

  60%,
  100% {
    transform: rotate(360deg) translateY(-200%);
  }
}
