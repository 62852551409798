@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'base/_reset';
@import 'base/_fonts';
@import 'components/_jquery.formstyler';
@import 'components/_ion.rangeSlider.min';
@import 'components/_slick';
@import 'layout/_header';
@import 'layout/_footer';
@import 'layout/_content';
@import url('https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&display=swap');
@import 'rc-slider/assets/index.css';
@import 'react-dropdown/style.css';
